html,
body,
.main-body,
.background-bz {
    height: 100%;
}

.loaderSvg {
    height: 8px;
}

.nav-tabs {
    margin-bottom: 1rem;
}

.vertical-offset-100 {
    padding-top: 100px;
}

.vertical-offset-20 {
    padding-top: 20px;
}

.main-body {
    min-height: 100%;
}

.background-bz {
    background-image: linear-gradient(109.6deg, #005378 11.2%, #008f9c 91.1%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100%;
}

.user-form {
    margin-top: 20px;
}

.nav-item,
.nav-link {
    cursor: pointer;
}

.nav-link a {
    cursor: pointer;
}

body {
    /*font-family: Helvetica, sans-serif;*/
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    /*width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-appearance: none;*/
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input::-ms-clear {
    display: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
    border-top: 0;
}

.react-autosuggest__section-title {
    padding: 10px 0 0 10px;
    font-size: 12px;
    color: #777;
}

.highlight {
    color: #5cb3fd;
    font-weight: bold;
}

/**
* Theme custom CSS
**/

body {
    padding: 0px;
}

.menu-top-w .menu-top-i .logo-w img {
    width: 150px;
}

.users-list-w {
    width: 100%;
}

.users-list-w .user-w .user-avatar-w .user-avatar img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.team-banner {
    background-color: #21d4fd;
    background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
}

.user-profile .up-main-info {
    padding: 5% 5% 5% 5%;
}

.littleNumbers .el-tablo .value {
    font-size: 1rem;
}

.content-box {
    padding-top: 1rem;
}

.interval-switcher {
    margin-bottom: 1rem;
}

#bulldozair-logo {
    width: 150px;
}

.menu-side {
    height: 100%;
}

#mainDiv {
    height: 100%;
}

.layout-w {
    min-height: 100%;
}

.loginBox {
    padding: 25px;
}

/**
MULTI SELECT
**/

.Select {
    z-index: 5;
}

/**
CHART

**/

.chartjs-chart-monitor {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;
}

.chartjs-chart-monitor {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;
}

/**
Billing group export
*/

.billing-group-export-block {
    margin-bottom: 30px;
}

.tabs-control-custom {
    margin-bottom: 0.5rem;
}

.image-button-select-all {
    width: 0.9rem;
}

.btn-move {
    height: 36px;
}

.move-to-text {
    padding-top: 8px;
}

/* Spinner */

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 18px;
}
.lds-ellipsis div {
    position: absolute;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 7px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 7px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 46px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

.dropdown .btn:not(.dropdown-toggle) {
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.dropdown .btn.dropdown-toggle {
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
